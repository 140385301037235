$font1: "Rubik Mono One", sans-serif;
$font2: 'Montserrat';
$resolution: 1920;
$resolution2: 768;
$blue: #24186a;
$yellow: #fdb315;
$red: #f04132;

a{
	text-decoration: none;
}
*:focus{
	outline: none;
}
html{
	width: 100%;
}

body{
	color: #ffffff;
	font-family: $font1;
	margin: 0;
	font-size: 1vw;
	background: #24186a;
	width: 100%;
}

.container{
	width: vw(1880);
	margin: 0 auto;
	position: relative;
	@include media((
					width: (md: vw2(728)),
	));
}

.social-block{
	@include media((
					display: (md: flex),
					justify-content: (md: center),
	));
	.soc-link{
		border: none;
		background: $red;
		width: vw(50);
		height: vw(50);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		@include media((
						width: (md: vw2(50)),
						height: (md: vw2(50)),
						background: (md: $blue),
		));
		svg{
			width: vw(20);
			height: vw(20);
			object-fit: contain;
			@include media((
							width: (md: vw2(20)),
							height: (md: vw2(20)),
			));
			*{
				fill: white;
			}
		}
		&:hover{
			background: #cb382e;
		}
		+.soc-link{
			margin-top: vw(10);
			@include media((
							margin-top: (md: 0),
							margin-left: (md: vw2(10)),
			));
		}
	}
}

.title{
	span{
		color: white;
		display: inline-block;
		&.yellow{
			background-color: $yellow;
		}
		&.blue{
			background-color: $blue;
		}
		&.red{
			background-color: $red;
		}
	}
}

.text_style{
	color: #ffffff;
	font-family: $font2;
	font-size: vw(24);
	font-weight: 500;
	line-height: vw(27);

	@include media((
					font-size: (md: vw2(24)),
					line-height: (md: vw2(27)),
	));
	p{
		+p{
			margin-top: vw(25);
			@include media((
							margin-top: (md: vw2(25)),
			));
		}
	}
}

.blur {
	filter: blur(50px);
	transition: 1s linear;
}

.section{
	overflow: hidden;
}

.section_one{
	min-height: 100vh;
	height: max-content;
	background: url("../images/bg-top.jpg") left center no-repeat;
	background-size: cover;
	position: relative;
	@include media((
					background: (md: url("../images/bg-top_mobile.jpg") left top no-repeat),
					background-size: (md: 100% auto),
	));
	&::before{
		content: "";
		background: url("../images/bg.png") left center no-repeat;
		background-size: cover;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: vw(916);
		pointer-events: none;

		@include media((
						height: (md: calc(100% - #{vw2(308)})),
						width: (md: 100%),
						top: (md: auto),
						bottom: (md: 0),
						background: (md: url("../images/bgm1.png") center top no-repeat),
						background-size: (md: cover),
		));
	}
	.container{
		min-height: 100vh;
		height: max-content;
		@include media((
		));
	}
	.logo{
		display: block;
		position: absolute;
		top: vw(37);
		left: vw(25);
		width: vw(337);
		@include media((
						top: (md: vw2(37)),
						left: (md: vw2(25)),
						width: (md: vw2(337)),
		));
		img{
			width: 100%;
			display: block;
		}
	}
	.social-block{
		position: absolute;
		left: vw(25);
		bottom: vw(38);
		@include media((
						position: (md: static),
						margin-top: (md: 5vh),
		));
	}

	.maxim{
		display: block;
		width: vw(232);
		position: absolute;
		right: vw(25);
		top: vw(23);

		@include media((
						top: (md: vw2(23)),
						right: (md: 0),
						width: (md: vw2(232)),
		));
		img{
			width: 100%;
			display: block;
			&.desktop{
				display: block;
				@include media((
								display: (md:none),
				));
			}
			&.mobile{
				display: none;
				@include media((
								display: (md:block),
				));
			}
		}
	}
	.daa{
		display: flex;
		align-items: center;
		justify-content: center;
		width: vw(296);
		height: vw(208);
		position: absolute;
		left: vw(1110);
		top: vw(55);
		background: url("../images/daa.svg") center center no-repeat;
		background-size: contain;
		@include media((
						width: (md: vw2(195)),
						height: (md: vw2(137)),
						left: (md: vw2(519)),
						top: (md: vw2(113)),
		));
		span{
			color: $red;
			font-size: vw(36);
			@include media((
							font-size: (md: vw2(24)),
			));
		}
	}

	.right{
		padding-top: 25vmin;
		margin-right: vw(25);
		@include media((
						padding-top: (md: 30vh),
						margin: (md: 0 vw2(5)),
		));
		.title{
			text-align: right;
			font-size: vw(105);
			font-weight: 400;
			line-height: 0.8;
			@include media((
							font-size: (md: vw2(80)),
			));
			span{
				padding: vw(25)  vw(25)  vw(22);
				@include media((
								padding: (md: vw2(17)  vw2(17)  vw2(15)),
				));
			}
		}
		.text{
			width: vw(760);
			margin-top: vw(30);
			text-align: right;
			margin-left: auto;
			@include media((
							width: (md: 95%),
							margin-top: (md: 5vh),
			));
		}
	}

	.arrow-down{
		width: vw(67.188);
		height: vw(50);
		display: block;
		background: url("../images/arr-b.png") center center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: vw(50);
		left: 50%;
		margin-left: vw(-34);
		animation-name: arrow;
		animation-duration: 0.5s;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-fill-mode: both;
		animation-play-state: running;
		cursor: pointer;
		@include media((
						width: (md: vw2(32)),
						margin-left: (md: auto),
						margin-right: (md: auto),
						margin-top: (md: 2vh),
						height: (md: vw2(25)),
						position: (md: static),
		));
	}
}

@keyframes arrow {
	0% {transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1); opacity: 1; animation-timing-function: ease-in-out}
	100% {transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,20,0,1); opacity: 1; }
}



.section_two{
	position: relative;
	min-height: 100vh;
	height: max-content;
	box-sizing: border-box;
	&::before{
		content: "";
		background: url("../images/bg2.png") right center no-repeat;
		background-size: cover;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: vw(1111);
		pointer-events: none;

		@include media((
						width: (md: vw2(515)),
						background: (md: url("../images/bgm2.png") right top no-repeat),
						background-size: (md: cover),
		));
	}
	.container{
		position: relative;
		min-height: 100vh;
		height: max-content;
		display: flex;
		align-items: center;
		padding-top: 3vh;
		padding-bottom: 3vh;
		box-sizing: border-box;
	}


	.middle{
		position: absolute;
		width: vw(400*0.7);
		left: 50%;
		top: 50%;
		margin-top: vw(-300);
		margin-left: vw(-200*0.7);
		pointer-events: none;
		z-index: 10;
		@include media((
						width: (md: vw2(235)),
						left: (md: vw2(-20)),
						margin-left: (md: 0),
						margin-top: (md: vw2(-145)),
		));
		.title{
			color: #ffffff;
			font-size: vw(105*0.7);
			font-weight: 400;
			text-align: center;
			@include media((
							font-size: (md: vw2(72)),
			));
			span{
				box-sizing: border-box;
				width: 100%;
			}
		}
		.text{
			color: #ffffff;
			font-family: $font2;
			font-size: vw(26*0.7);
			font-weight: 500;
			text-align: center;
			margin-top: vw(240*0.7);

			@include media((
							font-size: (md: vw2(26)),
							margin-top: (md: vw2(13)),
							text-align: (md: left),
							margin-left: (md: vw2(20)),
			));
		}
	}

	.swiper-container{
	}
	.slider{
		width: 100%;

		.girls{
			display: flex;
			justify-content: space-between;
			width: vw(1604*0.8);
			margin: 0 auto;

			@include media((
							width: (md: 100%),
							flex-direction: (md: column),
			));
			.girl-block{
				width: vw(583*0.8);
				position: relative;
				@include media((
								width: (md: 100%),
								height: (md: vw2(445)),
				));
				.img{
					display: flex;
					width: 100%;
					height: vw(795*0.8);
					align-items: center;
					justify-content: center;
					position: relative;
					overflow: hidden;
					background: black;
					box-sizing: border-box;
					@include media((
									width: (md: vw2(326)),
									height: (md: vw2(445)),
									margin: (md: 0 auto),
					));
					.image{
						width: 100%;
						height: 100%;
						display: block;
						background-size: cover;
						background-position: center;
						position: absolute;
						left: 0;
						top: 0;
						svg{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.likes{
						position: relative;
						z-index: 10;
						color: $red;
						font-size: vw(105*0.8);
						line-height: 0.8;
						display: none;
						@include media((
										font-size: (md: vw2(59)),
						));
					}
					.like{
						position: absolute;
						z-index: 2;
						width: vw(90*0.8);
						height: vw(90*0.8);
						border-radius: 100%;
						background: $blue;
						bottom: vw(25*0.8);
						left: 50%;
						margin-left: vw(-45*0.8);
						cursor: pointer;
						border: none;
						display: flex;
						align-items: center;
						justify-content: center;
						@include media((
										width: (md: vw2(50)),
										height: (md: vw2(50)),
										margin-left: (md: 0),
										left: (md: auto),
										bottom: (md: vw2(18)),
										right: (md: vw2(18)),
						));
						svg{
							width: 80%;
							margin-top: vw(5*0.8);
							path{
								fill: $red;
							}
						}
						&:hover{
							transform: scale(1.1);
						}
					}
					&.liked{
						border: $red vw(10*0.8) solid;
						@include media((
										border-width: (md: vw2(8)),
						));
					}
				}
				.flex-row{
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin-top: vw(23*0.8);
					@include media((
									margin-top: (md: 0),
									position: (md: absolute),
									left: (md: 0),
									width: (md: vw2(648)),
					));
					audio{
						display: none;
					}
					.play{
						display: block;
						border: none;
						background: url("../images/play.png") center center no-repeat;
						background-size: contain;
						width: vw(93*0.8);
						height: vw(93*0.8);
						cursor: pointer;
						flex-shrink: 0;
						@include media((
										width: (md: vw2(93)),
										height: (md: vw2(93)),
						));
						&:hover{
							transform: scale(1.1);
						}
						&.pause{
							background-image: url("../images/pause.png");
						}
					}
					.name{
						color: $red;
						font-size: vw(36*0.8);
						max-width: 75%;
						padding: vw(5*0.8) vw(10*0.8);
						@include media((
										font-size: (md: vw2(36)),
										padding: (md: vw2(5) vw2(10)),
						));
						span{
							background: $blue;
							white-space:pre-wrap;
							padding: vw(5*0.8) vw(10*0.8);
							padding-left:0;
							box-shadow: vw(-10*0.8) 0 0 $blue;
							@include media((
											font-size: (md: vw2(36)),
											padding: (md: vw2(5) vw2(10)),
											padding-left: (md:0),
											box-shadow: (md: vw2(-10) 0 0 $blue),
							));
						}
					}
					.age{
						margin-top: vw(10*0.8);
						margin-left: vw(10*0.8);
						color: $blue;
						@include media((
										display: (md: none)
						));
					}
				}
				&.left-girl{
					.flex-row{
						@include media((
										top: (md: 0),
						));
					}
				}

				&.right-girl{
					@include media((
									margin-top: (md: vw2(42)),
					));
					.flex-row{
						@include media((
										bottom: (md: 0),
						));
						.play{
							background: url("../images/play_2.png") center center no-repeat;
							background-size: contain;
							@include media((
											background-image: (md:  url("../images/play.png")),
											position: (md:  relative),
											top: (md: vw2(-345)),
							));
							&.pause{
								background-image: url("../images/pause_2.png");
								@include media((
												background-image: (md:  url("../images/pause.png")),
								));
							}
						}
						.name{
							color: $blue;
							@include media((
											color: (md: $red),
							));
							span{
								background: $red;
								box-shadow: vw(-10*0.8) 0 0 $red;
								@include media((
												background: (md: $blue),
												box-shadow: (md: vw2(-10) 0 0 $blue),
								));
							}
						}
						.age{
							color: $red;
							@include media((
											color: (md: $blue),
							));
						}
					}
				}
			}
		}
		.swiper-button-prev,
		.swiper-button-next{
			width: vw(50*0.8);
			height: vw(87*0.8);
			margin-top: vw(-80);
			display: block;
			background: url("../images/arr-r.png") center center no-repeat;
			background-size: contain;
			@include media((
							display: (md: none),
			));
			&::after{
				display: none;
			}
		}
		.swiper-button-next{
			right: vw(200*0.8);
		}
		.swiper-button-prev{
			transform: scaleX(-1);
			left: vw(200*0.8);
		}

		.swiper-pagination{
			display: none;
			bottom: 50%;
			margin-bottom: vw2(-7);
			@include media((
							display: (md: block),
			));
			.swiper-pagination-bullet{
				width: vw2(12);
				height: vw2(12);
				margin: 0 vw2(2.5);
				background: #f7d9b7;
				opacity: 1;
				&-active{
					background: $red;
				}
			}
		}
	}
}

$resolution: 2496;
.section_three{
	position: relative;
	background: $yellow;
	min-height: 100vh;
	height: max-content;
	box-sizing: border-box;
	&::before{
		content: "";
		background: url("../images/bg3.png") right center no-repeat;
		background-size: cover;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: vw(1436);
		pointer-events: none;
		@include media((
						background: (md: url("../images/bgm3.png") right center no-repeat),
						background-size: (md: cover),
						width: (md: vw2(521)),
		));
	}

	.container{
		min-height: 100vh;
		height: max-content;
		padding-top: 3vh;
		padding-bottom: 3vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.title{
		color: #ffffff;
		font-size: vw(105*0.7);
		font-weight: 400;
		line-height: 0.8;
		text-align: center;
		@include media((
						font-size: (md: vw2(105)),
						width: (md: 100%),
						box-sizing: (md: border-box),
		));
		span{
			padding: vw(20)  vw(20)  vw(18);
			@include media((
							padding: (md: vw2(20)  vw2(20)  vw2(18)),
							width: (md: 100%),
							box-sizing: (md: border-box),
			));
		}
	}

	.list{
		margin-top: 2vh;
		padding: 0 vw(60);
		@include media((
						padding: (md: 0),
		));
		ol {
			list-style: none;
			padding-left: 0;
			counter-reset: item;
			display: table;
			text-align: left;
			margin: 0 auto;
			@include media((
							width: (md: 100%),
			));
			>li {
				display: table-row;
				counter-increment: item;
				&::before {
					display: table-cell;
					counter-increment: li;
					content: counter(item, decimal-leading-zero);
					vertical-align: middle;
					color: #ffffff;
					font-size: vw(80);
					font-weight: 400;
					line-height: 0.8;
					padding: 1vh vw(25) 1vh vw(0);
					@include media((
									font-size: (md: vw2(72)),
									padding: (md: 1vh vw2(15) 1vh 0),
					));
				}
				>div{
					display: table-cell;
					vertical-align: middle;
					&.name{
						color: $red;
						font-size: vw(50);
						font-weight: 400;
						padding: vw(15) vw(40) vw(15) vw(25);
						width: vw(1000);
						box-sizing: border-box;
						@include media((
										font-size: (md: vw2(42)),
										padding: (md: vw2(20) vw2(30) vw2(20) vw2(15)),
										width: (md: auto),
						));
						span{
							padding: vw(10) vw(40) vw(10) vw(0);
							background: $blue;
							box-shadow: vw(-25) 0 0 $blue;
							white-space: pre-wrap;
							@include media((
											box-shadow: (md: vw2(-15) 0 0 $blue),
											padding: (md: vw2(8) vw2(15) vw2(8) vw2(0)),
							));
						}
					}
					&.score{
						color: $blue;
						font-size: vw(70);
						font-weight: 400;
						line-height: 0.8;
						padding: 1vh vw(0) 1vh vw(0);
						@include media((
										font-size: (md:  vw2(60)),
						));
					}
				}
			}
		}

	}
}


.section_four {
	position: relative;
	padding-top: vw(50*1.3);
	background: $red;
	padding-bottom: vw(40*1.3);
	@include media((
					padding-top: (md: vw2(23)),
					padding-bottom: (md: vw2(49)),
	));

	&::before {
		content: "";
		background: url("../images/bg4.png") right center no-repeat;
		background-size: cover;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: vw(1073*1.3);
		pointer-events: none;

		@include media((
						background: (md: url("../images/bgm4.png") right center no-repeat),
						background-size: (md: cover),
						width: (md: vw2(513)),
		));
	}

	.title{
		color: #ffffff;
		font-size: vw(105);
		font-weight: 400;
		line-height: 0.8;
		margin-left: vw(383);
		@include media((
						font-size: (md: vw2(67)),
						margin-left: (md: vw2(0)),
		));
		span{
			padding: vw(25)  vw(25)  vw(22);
			@include media((
							padding: (md: vw2(13)  vw2(18)  vw2(9)),
			));
		}
	}

	.main-block{
		position: relative;
		padding-top: vw(512);
		margin-top: vw(43);
		width: vw(1780);
		margin-left: auto;
		margin-right: auto;
		@include media((
						width: (md: vw2(768)),
						margin-left: (md: vw2(-20)),
						margin-right: (md: vw2(-20)),
						margin-top: (md: vw2(32)),
						padding-top: (md: vw2(0)),
		));
		.main-img{
			width: vw(1536);
			margin: 0 auto;
			@include media((
							width: (md: vw2(816)),
							margin-left: (md: vw2(-24)),
							margin-right: (md: vw2(-24)),
							margin-top: (md: vw2(270)),
			));
			img{
				width: 100%;
				display: block;
			}
		}
		.top-text{
			position: absolute;
			top: 0;
			color: $red;
			font-size: vw(36);
			padding: vw(8) vw(15) vw(8) vw(15);
			@include media((
							font-size: (md: vw2(36)),
							padding: (md: vw2(8) vw2(15) vw2(8) vw2(15)),
							position: (md: static),
							margin-left: (md: vw2(20)),
							color: (md: $yellow),
			));
			span{
				padding: vw(8) vw(15) vw(8) vw(0);
				background: $blue;
				box-shadow: vw(-15) 0 0 $blue;
				white-space: pre-wrap;
				@include media((
								box-shadow: (md: vw2(-15) 0 0 $blue),
								padding: (md: vw2(8) vw2(15) vw2(8) vw2(0)),
				));
			}
			&-1{
				left: vw(83);
			}
			&-2{
				left: vw(1122);
				@include media((
								font-size: (md: vw2(30)),
								color: (md: $blue),
								margin-top: (md: vw2(33)),
				));
				span{
					@include media((
									box-shadow: (md: none),
									background: (md: none),
					));
				}
			}
		}
		.note{
			position: absolute;
			img{
				width: 100%;
				display: block;
			}
			&-1{
				left: vw(1388);
				top: vw(420);
				width: vw(69);
				@include media((
								width: (md: vw2(51)),
								left: (md: vw2(660)),
								top: (md: vw2(250)),
				));
			}
			&-2{
				left: vw(1343);
				top: vw(290);
				width: vw(24);
				@include media((
								display: (md: none),
				));
			}
			&-3{
				left: vw(1067);
				top: vw(640);
				width: vw(71);
				@include media((
								width: (md: vw2(48)),
								left: (md: vw2(507)),
								top: (md: vw2(514)),
				));
			}
			&-4{
				left: vw(607);
				top: vw(122);
				width: vw(57);
				@include media((
								display: (md: none),
				));
			}
			&-5{
				left: vw(577);
				top: vw(666);
				width: vw(24);
				@include media((
								display: (md: none),
				));
			}
			&-6{
				left: vw(153);
				top: vw(1184);
				width: vw(57);
				@include media((
								width: (md: vw2(34)),
								left: (md: vw2(17)),
								top: (md: vw2(701)),
				));
			}
		}

		.cloud{
			position: absolute;
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			&-text{
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: vw(24);
				font-weight: 400;
				@include media((
								font-size: (md: vw2(16)),
				));
			}
			&-1{
				&-1{
					width: vw(285);
					height: vw(249);
					left: vw(121);
					top: vw(690);
					background-image: url("../images/t11.png");
					z-index: 1;
					@include media((
									width: (md: vw2(185)),
									height: (md: vw2(161)),
									left: (md: vw2(57)),
									top: (md: vw2(528)),
					));
				}
				&-2{
					width: vw(287);
					height: vw(378);
					left: vw(8);
					top: vw(443);
					background-image: url("../images/t12.png");
					color: $blue;
					@include media((
									width: (md: vw2(186)),
									height: (md: vw2(245)),
									left: (md: vw2(-8)),
									top: (md: vw2(360)),
					));
					span{
						margin-bottom: vw(60);
						margin-right: vw(10);
						@include media((
										margin-bottom: (md: vw2(32)),
										margin-right: (md: vw2(8)),
						));
					}
				}
			}
			&-2{
				&-1{
					width: vw(377);
					height: vw(392);
					left: vw(316);
					top: vw(217);
					background-image: url("../images/t21.png");
					@include media((
									width: (md: vw2(223)),
									height: (md: vw2(238)),
									left: (md: vw2(194)),
									top: (md: vw2(350)),
					));
				}
				&-2{
					width: vw(382);
					height: vw(251);
					left: vw(535);
					top: vw(397);
					background-image: url("../images/t22.png");
					color: $blue;
					z-index: 2;
					@include media((
									width: (md: vw2(226)),
									height: (md: vw2(152)),
									left: (md: vw2(259)),
									top: (md: vw2(551)),
									font-size: (md: vw2(15)),
					));
					span{
						margin-left: vw(55);
						margin-top: vw(30);
						@include media((
										margin-left: (md: vw2(39)),
										margin-top: (md: vw2(17)),
						));
					}
				}
			}
			&-3{
				&-1{
					width: vw(372);
					height: vw(320);
					left: vw(708);
					top: vw(44);
					background-image: url("../images/t31.png");
					@include media((
									width: (md: vw2(226)),
									height: (md: vw2(195)),
									left: (md: vw2(421)),
									top: (md: vw2(297)),
					));
				}
				&-2{
					width: vw(308);
					height: vw(269);
					left: vw(1009);
					top: vw(211);
					background-image: url("../images/t32.png");
					color: white;
					z-index: 1;
					@include media((
									width: (md: vw2(187)),
									height: (md: vw2(164)),
									left: (md: vw2(604)),
									top: (md: vw2(400)),
									font-size: (md: vw2(15)),
					));
					span{
						margin-top: vw(26);
						margin-right: vw(20);
						@include media((
										margin-top: (md: vw2(14)),
										margin-right: (md: vw2(9)),
						));
					}
				}
			}
			&-4{
				&-1{
					width: vw(377);
					height: vw(248);
					left: vw(1226);
					top: vw(538);
					background-image: url("../images/t41.png");
					z-index: 1;
					@include media((
									width: (md: vw2(263)),
									height: (md: vw2(173)),
									left: (md: vw2(393)),
									top: (md: vw2(650)),
					));
				}
				&-2{
					width: vw(275);
					height: vw(399);
					left: vw(1497);
					top: vw(351);
					background-image: url("../images/t42.png");
					color: $red;
					@include media((
									width: (md: vw2(192)),
									height: (md: vw2(278)),
									left: (md: vw2(582)),
									top: (md: vw2(518)),
									font-size: (md: vw2(17)),
					));
					span{
						margin-bottom: vw(5);
						margin-left: vw(14);
						@include media((
										margin-bottom: (md: vw2(4)),
										margin-left: (md: vw2(11)),
						));
					}
				}
			}
		}
	}

	.text-bottom{
		display: flex;
		margin-top: vw(-25);
		margin-left: vw(390);

		@include media((
						margin-top: (md: vw2(12)),
						margin-left: (md: vw2(10)),
						margin-right: (md: vw2(10)),
						display: (md: block),
		));
		p{
			color: $blue;
			font-family: $font2;
			font-size: vw(24);
			font-weight: 500;
			line-height: vw(27);
			@include media((
							font-size: (md: vw2(24)),
							line-height: (md: vw2(27)),
			));
			+p{
				margin-top: 1em;
			}
		}
		.left{
			width: vw(780);
			@include media((
							width: (md: 100%),
			));
		}
		.right{
			width: vw(650);
			margin-left: vw(60*1.3);
			@include media((
							width: (md: 100%),
							margin-left: (md: 0),
							margin-top: (md: vw2(24)),
			));
		}
	}

	.spec-block{
		margin-top: vw(55);
		@include media((
						margin-top: (md: vw2(30)),
		));
		.text{
			color: $blue;
			font-size: vw(36);
			font-weight: 400;
			padding: vw(8) vw(16) vw(8) vw(16);
			box-sizing: border-box;
			margin-left: vw(338);
			line-height: 1.4;
			width: vw(1820);
			@include media((
							width: (md: 100%),
							font-size: (md: vw2(30)),
							padding: (md:  vw2(8) vw2(16) vw2(8) vw2(16)),
							margin-left: (md: 0),
			));
			span{
				padding: vw(8) vw(16) vw(8) vw(0);
				background: $yellow;
				box-shadow: vw(-16) 0 0 $yellow;
				white-space: pre-wrap;
				@include media((
								padding: (md:  vw2(8) vw2(16) vw2(8) vw2(0)),
								box-shadow: (md: vw2(-16) 0 0 $yellow),
				));
			}
		}
		.icons{
			margin-top: vw(44);
			margin-left: vw(338);
			position: relative;
			height: vw(535);
			@include media((
							height: (md:  vw2(985)),
							margin-left: (md:  0),
							margin-top: (md:  vw2(7)),
			));
			.cloud {
				position: absolute;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				&-text {
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: vw(24);
					font-weight: 400;
					@include media((
									font-size: (md:  vw2(24)),
					));
				}

				&-5 {
					&-1 {
						width: vw(376);
						height: vw(304);
						left: vw(0);
						top: vw(7);
						background-image: url("../images/t51.png");
						z-index: 1;
						@include media((
										width: (md:  vw2(376)),
										height: (md:  vw2(304)),
										left: (md:  vw2(-3)),
										top: (md:  vw2(25)),
						));
					}

					&-2 {
						width: vw(426);
						height: vw(280);
						left: vw(98);
						top: vw(253);
						background-image: url("../images/t52.png");
						color: $blue;
						font-size: vw(19);
						@include media((
										width: (md:  vw2(426)),
										height: (md:  vw2(280)),
										left: (md:  vw2(14)),
										top: (md:  vw2(287)),
										font-size: (md:  vw2(19)),
										background-image: (md:  url("../images/t52m.png")),
						));
						span {
							margin-top: vw(15);
							margin-left: vw(80);
							@include media((
											margin-top: (md:  vw2(15)),
											margin-left: (md:  vw2(80)),
							));
						}
					}
				}
				&-6 {
					&-1 {
						width: vw(263);
						height: vw(278);
						left: vw(545);
						top: vw(253);
						background-image: url("../images/t61.png");
						z-index: 1;
						@include media((
										width: (md:  vw2(263)),
										height: (md:  vw2(278)),
										left: (md:  vw2(472)),
										top: (md:  vw2(171)),
						));
					}

					&-2 {
						width: vw(316);
						height: vw(214);
						left: vw(423);
						top: vw(5);
						background-image: url("../images/t62.png");
						color: $red;
						@include media((
										width: (md:  vw2(316)),
										height: (md:  vw2(214)),
										left: (md:  vw2(359)),
										top: (md:  vw2(0)),
										background-image: (md:  url("../images/t62m.png")),
						));
						span {
							margin-right: vw(22);
							margin-bottom: vw(8);
							@include media((
											margin-right: (md:  vw2(22)),
											margin-bottom: (md:  vw2(8)),
							));
						}
					}
				}
				&-7{
					&-1 {
						width: vw(269);
						height: vw(231);
						left: vw(816);
						top: vw(14);
						background-image: url("../images/t71.png");
						z-index: 1;
						@include media((
										width: (md:  vw2(269)),
										height: (md:  vw2(231)),
										left: (md:  vw2(10)),
										top: (md:  vw2(515)),
						));
					}

					&-2 {
						width: vw(279);
						height: vw(226);
						left: vw(945);
						top: vw(225);
						background-image: url("../images/t72.png");
						color: $blue;
						@include media((
										width: (md:  vw2(279)),
										height: (md:  vw2(226)),
										left: (md:  vw2(88)),
										top: (md:  vw2(760)),
										background-image: (md: url("../images/t72m.png")),
						));
						span {
							margin-left: vw(24);
							margin-top: vw(17);
							@include media((
											margin-left: (md:  vw2(24)),
											margin-top: (md:  vw2(17)),
							));
						}
					}
				}
				&-8 {
					&-1 {
						width: vw(308);
						height: vw(269);
						left: vw(1463);
						top: vw(227);
						background-image: url("../images/t81.png");
						z-index: 1;
						@include media((
										width: (md:  vw2(308)),
										height: (md:  vw2(269)),
										left: (md:  vw2(429)),
										top: (md:  vw2(695)),
						));
					}

					&-2 {
						width: vw(372);
						height: vw(320);
						left: vw(1217);
						top: vw(0);
						background-image: url("../images/t82.png");
						color: $red;
						@include media((
										width: (md:  vw2(372)),
										height: (md:  vw2(320)),
										left: (md:  vw2(323)),
										top: (md:  vw2(470)),
						));
						span {
							margin-bottom: vw(32);
							@include media((
											margin-bottom: (md:  vw2(32)),
							));
						}
					}
				}
			}
		}

		.flex-block{
			margin-top: vw(24);
			display: flex;
			margin-left: vw(396);
			@include media((
							margin-top: (md:  vw2(42)),
							margin-left: (md: 0),
							display: (md: block),
			));
			p{
				color: $blue;
				font-family: $font2;
				font-size: vw(24);
				font-weight: 500;
				line-height: vw(27);
				@include media((
								font-size: (md:  vw2(24)),
								line-height: (md: vw2(27)),
				));
				+p{
					margin-top: 1em;
				}
			}
			.left{
				width: vw(575);
				margin-top: vw(58);
				@include media((
								width: (md:  95%),
								margin-left: (md: vw2(15)),
								margin-righ: (md: vw2(15)),
				));
			}
			.right{
				width: vw(520);
				margin-top: vw(70);
				@include media((
								width: (md:  95%),
								margin-top: (md: vw2(25)),
								margin-left: (md: vw2(15)),
								margin-righ: (md: vw2(15)),
				));
			}
			.center{
				width: vw(320);
				margin-left: vw(34);
				margin-right: vw(67);
				@include media((
								width: (md:  vw2(320)),
								margin: (md: vw2(25) auto 0),
				));
				img{
					width: 100%;
					display: block;
				}
			}
		}
		.last-text{
			color: $yellow;
			font-size: vw(36);
			font-weight: 400;
			padding: vw(8) vw(16) vw(8) vw(16);
			box-sizing: border-box;
			margin-left: vw(631);
			margin-top: vw(20);
			@include media((
							font-size: (md:  vw2(36)),
							padding: (md: vw2(8) vw2(16) vw2(8) vw2(16)),
							margin-left: (md: vw2(10)),
							margin-right: (md: vw2(10)),
							margin-top: (md: vw2(60)),
			));
			span{
				padding: vw(8) vw(16) vw(8) vw(0);
				background: $blue;
				box-shadow: vw(-16) 0 0 $blue;
				white-space: pre-wrap;
				@include media((
								padding: (md: vw2(8) vw2(16) vw2(8) vw2(0)),
								box-shadow: (md: vw2(-16) 0 0 $blue),
				));
			}
			a{
				color: $yellow;
				text-decoration: none;
				&:hover{
					color: $red;
				}
			}
		}
	}
}

$resolution: 1920;
.section_five{
	padding: vw(15) vw(17);
	@include media((
					padding: (md: vw2(25) vw2(20)),
	));
	img{
		width: 100%;
		@include media((
						transform: (md: scaleY(1.6)),
		));
	}
}