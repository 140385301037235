@import url(partials/reset.css);

@import url(partials/fonts.css);

@import url(partials/swiper.css);

@import url(partials/animate.css);

/* my style */

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

html {
  width: 100%;
}

body {
  color: #ffffff;
  font-family: "Rubik Mono One", sans-serif;
  margin: 0;
  font-size: 1vw;
  background: #24186a;
  width: 100%;
}

.container {
  width: 97.91667vw;
  margin: 0 auto;
  position: relative;
}

.social-block .soc-link {
  border: none;
  background: #f04132;
  width: 2.60417vw;
  height: 2.60417vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-block .soc-link svg {
  width: 1.04167vw;
  height: 1.04167vw;
  object-fit: contain;
}

.social-block .soc-link svg * {
  fill: white;
}

.social-block .soc-link:hover {
  background: #cb382e;
}

.social-block .soc-link + .soc-link {
  margin-top: 0.52083vw;
}

.title span {
  color: white;
  display: inline-block;
}

.title span.yellow {
  background-color: #fdb315;
}

.title span.blue {
  background-color: #24186a;
}

.title span.red {
  background-color: #f04132;
}

.text_style {
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.40625vw;
}

.text_style p + p {
  margin-top: 1.30208vw;
}

.blur {
  filter: blur(50px);
  transition: 1s linear;
}

.section {
  overflow: hidden;
}

.section_one {
  min-height: 100vh;
  height: max-content;
  background: url("../images/bg-top.jpg") left center no-repeat;
  background-size: cover;
  position: relative;
}

.section_one::before {
  content: "";
  background: url("../images/bg.png") left center no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 47.70833vw;
  pointer-events: none;
}

.section_one .container {
  min-height: 100vh;
  height: max-content;
}

.section_one .logo {
  display: block;
  position: absolute;
  top: 1.92708vw;
  left: 1.30208vw;
  width: 17.55208vw;
}

.section_one .logo img {
  width: 100%;
  display: block;
}

.section_one .social-block {
  position: absolute;
  left: 1.30208vw;
  bottom: 1.97917vw;
}

.section_one .maxim {
  display: block;
  width: 12.08333vw;
  position: absolute;
  right: 1.30208vw;
  top: 1.19792vw;
}

.section_one .maxim img {
  width: 100%;
  display: block;
}

.section_one .maxim img.desktop {
  display: block;
}

.section_one .maxim img.mobile {
  display: none;
}

.section_one .daa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.41667vw;
  height: 10.83333vw;
  position: absolute;
  left: 57.8125vw;
  top: 2.86458vw;
  background: url("../images/daa.svg") center center no-repeat;
  background-size: contain;
}

.section_one .daa span {
  color: #f04132;
  font-size: 1.875vw;
}

.section_one .right {
  padding-top: 25vmin;
  margin-right: 1.30208vw;
}

.section_one .right .title {
  text-align: right;
  font-size: 5.46875vw;
  font-weight: 400;
  line-height: 0.8;
}

.section_one .right .title span {
  padding: 1.30208vw 1.30208vw 1.14583vw;
}

.section_one .right .text {
  width: 39.58333vw;
  margin-top: 1.5625vw;
  text-align: right;
  margin-left: auto;
}

.section_one .arrow-down {
  width: 3.49938vw;
  height: 2.60417vw;
  display: block;
  background: url("../images/arr-b.png") center center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 2.60417vw;
  left: 50%;
  margin-left: -1.77083vw;
  animation-name: arrow;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-play-state: running;
  cursor: pointer;
}

@keyframes arrow {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    opacity: 1;
  }
}

.section_two {
  position: relative;
  min-height: 100vh;
  height: max-content;
  box-sizing: border-box;
}

.section_two::before {
  content: "";
  background: url("../images/bg2.png") right center no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 57.86458vw;
  pointer-events: none;
}

.section_two .container {
  position: relative;
  min-height: 100vh;
  height: max-content;
  display: flex;
  align-items: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  box-sizing: border-box;
}

.section_two .middle {
  position: absolute;
  width: 14.58333vw;
  left: 50%;
  top: 50%;
  margin-top: -15.625vw;
  margin-left: -7.29167vw;
  pointer-events: none;
  z-index: 10;
}

.section_two .middle .title {
  color: #ffffff;
  font-size: 3.82813vw;
  font-weight: 400;
  text-align: center;
}

.section_two .middle .title span {
  box-sizing: border-box;
  width: 100%;
}

.section_two .middle .text {
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 0.94792vw;
  font-weight: 500;
  text-align: center;
  margin-top: 8.75vw;
}

.section_two .slider {
  width: 100%;
}

.section_two .slider .girls {
  display: flex;
  justify-content: space-between;
  width: 66.83333vw;
  margin: 0 auto;
}

.section_two .slider .girls .girl-block {
  width: 24.29167vw;
  position: relative;
}

.section_two .slider .girls .girl-block .img {
  display: flex;
  width: 100%;
  height: 33.125vw;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: black;
  box-sizing: border-box;
}

.section_two .slider .girls .girl-block .img .image {
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.section_two .slider .girls .girl-block .img .image svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section_two .slider .girls .girl-block .img .likes {
  position: relative;
  z-index: 10;
  color: #f04132;
  font-size: 4.375vw;
  line-height: 0.8;
  display: none;
}

.section_two .slider .girls .girl-block .img .like {
  position: absolute;
  z-index: 2;
  width: 3.75vw;
  height: 3.75vw;
  border-radius: 100%;
  background: #24186a;
  bottom: 1.04167vw;
  left: 50%;
  margin-left: -1.875vw;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_two .slider .girls .girl-block .img .like svg {
  width: 80%;
  margin-top: 0.20833vw;
}

.section_two .slider .girls .girl-block .img .like svg path {
  fill: #f04132;
}

.section_two .slider .girls .girl-block .img .like:hover {
  transform: scale(1.1);
}

.section_two .slider .girls .girl-block .img.liked {
  border: #f04132 0.41667vw solid;
}

.section_two .slider .girls .girl-block .flex-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.95833vw;
}

.section_two .slider .girls .girl-block .flex-row audio {
  display: none;
}

.section_two .slider .girls .girl-block .flex-row .play {
  display: block;
  border: none;
  background: url("../images/play.png") center center no-repeat;
  background-size: contain;
  width: 3.875vw;
  height: 3.875vw;
  cursor: pointer;
  flex-shrink: 0;
}

.section_two .slider .girls .girl-block .flex-row .play:hover {
  transform: scale(1.1);
}

.section_two .slider .girls .girl-block .flex-row .play.pause {
  background-image: url("../images/pause.png");
}

.section_two .slider .girls .girl-block .flex-row .name {
  color: #f04132;
  font-size: 1.5vw;
  max-width: 75%;
  padding: 0.20833vw 0.41667vw;
}

.section_two .slider .girls .girl-block .flex-row .name span {
  background: #24186a;
  white-space: pre-wrap;
  padding: 0.20833vw 0.41667vw;
  padding-left: 0;
  box-shadow: -0.41667vw 0 0 #24186a;
}

.section_two .slider .girls .girl-block .flex-row .age {
  margin-top: 0.41667vw;
  margin-left: 0.41667vw;
  color: #24186a;
}

.section_two .slider .girls .girl-block.right-girl .flex-row .play {
  background: url("../images/play_2.png") center center no-repeat;
  background-size: contain;
}

.section_two .slider .girls .girl-block.right-girl .flex-row .play.pause {
  background-image: url("../images/pause_2.png");
}

.section_two .slider .girls .girl-block.right-girl .flex-row .name {
  color: #24186a;
}

.section_two .slider .girls .girl-block.right-girl .flex-row .name span {
  background: #f04132;
  box-shadow: -0.41667vw 0 0 #f04132;
}

.section_two .slider .girls .girl-block.right-girl .flex-row .age {
  color: #f04132;
}

.section_two .slider .swiper-button-prev,
.section_two .slider .swiper-button-next {
  width: 2.08333vw;
  height: 3.625vw;
  margin-top: -4.16667vw;
  display: block;
  background: url("../images/arr-r.png") center center no-repeat;
  background-size: contain;
}

.section_two .slider .swiper-button-prev::after,
.section_two .slider .swiper-button-next::after {
  display: none;
}

.section_two .slider .swiper-button-next {
  right: 8.33333vw;
}

.section_two .slider .swiper-button-prev {
  transform: scaleX(-1);
  left: 8.33333vw;
}

.section_two .slider .swiper-pagination {
  display: none;
  bottom: 50%;
  margin-bottom: -0.91146vw;
}

.section_two .slider .swiper-pagination .swiper-pagination-bullet {
  width: 1.5625vw;
  height: 1.5625vw;
  margin: 0 0.32552vw;
  background: #f7d9b7;
  opacity: 1;
}

.section_two .slider .swiper-pagination .swiper-pagination-bullet-active {
  background: #f04132;
}

.section_three {
  position: relative;
  background: #fdb315;
  min-height: 100vh;
  height: max-content;
  box-sizing: border-box;
}

.section_three::before {
  content: "";
  background: url("../images/bg3.png") right center no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 57.53205vw;
  pointer-events: none;
}

.section_three .container {
  min-height: 100vh;
  height: max-content;
  padding-top: 3vh;
  padding-bottom: 3vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.section_three .title {
  color: #ffffff;
  font-size: 2.94471vw;
  font-weight: 400;
  line-height: 0.8;
  text-align: center;
}

.section_three .title span {
  padding: 0.80128vw 0.80128vw 0.72115vw;
}

.section_three .list {
  margin-top: 2vh;
  padding: 0 2.40385vw;
}

.section_three .list ol {
  list-style: none;
  padding-left: 0;
  counter-reset: item;
  display: table;
  text-align: left;
  margin: 0 auto;
}

.section_three .list ol > li {
  display: table-row;
  counter-increment: item;
}

.section_three .list ol > li::before {
  display: table-cell;
  counter-increment: li;
  content: counter(item, decimal-leading-zero);
  vertical-align: middle;
  color: #ffffff;
  font-size: 3.20513vw;
  font-weight: 400;
  line-height: 0.8;
  padding: 1vh 1.0016vw 1vh 0vw;
}

.section_three .list ol > li > div {
  display: table-cell;
  vertical-align: middle;
}

.section_three .list ol > li > div.name {
  color: #f04132;
  font-size: 2.00321vw;
  font-weight: 400;
  padding: 0.60096vw 1.60256vw 0.60096vw 1.0016vw;
  width: 40.0641vw;
  box-sizing: border-box;
}

.section_three .list ol > li > div.name span {
  padding: 0.40064vw 1.60256vw 0.40064vw 0vw;
  background: #24186a;
  box-shadow: -1.0016vw 0 0 #24186a;
  white-space: pre-wrap;
}

.section_three .list ol > li > div.score {
  color: #24186a;
  font-size: 2.80449vw;
  font-weight: 400;
  line-height: 0.8;
  padding: 1vh 0vw 1vh 0vw;
}

.section_four {
  position: relative;
  padding-top: 2.60417vw;
  background: #f04132;
  padding-bottom: 2.08333vw;
}

.section_four::before {
  content: "";
  background: url("../images/bg4.png") right center no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 55.88542vw;
  pointer-events: none;
}

.section_four .title {
  color: #ffffff;
  font-size: 4.20673vw;
  font-weight: 400;
  line-height: 0.8;
  margin-left: 15.34455vw;
}

.section_four .title span {
  padding: 1.0016vw 1.0016vw 0.88141vw;
}

.section_four .main-block {
  position: relative;
  padding-top: 20.51282vw;
  margin-top: 1.72276vw;
  width: 71.3141vw;
  margin-left: auto;
  margin-right: auto;
}

.section_four .main-block .main-img {
  width: 61.53846vw;
  margin: 0 auto;
}

.section_four .main-block .main-img img {
  width: 100%;
  display: block;
}

.section_four .main-block .top-text {
  position: absolute;
  top: 0;
  color: #f04132;
  font-size: 1.44231vw;
  padding: 0.32051vw 0.60096vw 0.32051vw 0.60096vw;
}

.section_four .main-block .top-text span {
  padding: 0.32051vw 0.60096vw 0.32051vw 0vw;
  background: #24186a;
  box-shadow: -0.60096vw 0 0 #24186a;
  white-space: pre-wrap;
}

.section_four .main-block .top-text-1 {
  left: 3.32532vw;
}

.section_four .main-block .top-text-2 {
  left: 44.95192vw;
}

.section_four .main-block .note {
  position: absolute;
}

.section_four .main-block .note img {
  width: 100%;
  display: block;
}

.section_four .main-block .note-1 {
  left: 55.60897vw;
  top: 16.82692vw;
  width: 2.76442vw;
}

.section_four .main-block .note-2 {
  left: 53.80609vw;
  top: 11.61859vw;
  width: 0.96154vw;
}

.section_four .main-block .note-3 {
  left: 42.7484vw;
  top: 25.64103vw;
  width: 2.84455vw;
}

.section_four .main-block .note-4 {
  left: 24.31891vw;
  top: 4.88782vw;
  width: 2.28365vw;
}

.section_four .main-block .note-5 {
  left: 23.11699vw;
  top: 26.68269vw;
  width: 0.96154vw;
}

.section_four .main-block .note-6 {
  left: 6.12981vw;
  top: 47.4359vw;
  width: 2.28365vw;
}

.section_four .main-block .cloud {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section_four .main-block .cloud-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.96154vw;
  font-weight: 400;
}

.section_four .main-block .cloud-1-1 {
  width: 11.41827vw;
  height: 9.97596vw;
  left: 4.84776vw;
  top: 27.64423vw;
  background-image: url("../images/t11.png");
  z-index: 1;
}

.section_four .main-block .cloud-1-2 {
  width: 11.4984vw;
  height: 15.14423vw;
  left: 0.32051vw;
  top: 17.7484vw;
  background-image: url("../images/t12.png");
  color: #24186a;
}

.section_four .main-block .cloud-1-2 span {
  margin-bottom: 2.40385vw;
  margin-right: 0.40064vw;
}

.section_four .main-block .cloud-2-1 {
  width: 15.10417vw;
  height: 15.70513vw;
  left: 12.66026vw;
  top: 8.69391vw;
  background-image: url("../images/t21.png");
}

.section_four .main-block .cloud-2-2 {
  width: 15.30449vw;
  height: 10.05609vw;
  left: 21.43429vw;
  top: 15.90545vw;
  background-image: url("../images/t22.png");
  color: #24186a;
  z-index: 2;
}

.section_four .main-block .cloud-2-2 span {
  margin-left: 2.20353vw;
  margin-top: 1.20192vw;
}

.section_four .main-block .cloud-3-1 {
  width: 14.90385vw;
  height: 12.82051vw;
  left: 28.36538vw;
  top: 1.76282vw;
  background-image: url("../images/t31.png");
}

.section_four .main-block .cloud-3-2 {
  width: 12.33974vw;
  height: 10.77724vw;
  left: 40.42468vw;
  top: 8.45353vw;
  background-image: url("../images/t32.png");
  color: white;
  z-index: 1;
}

.section_four .main-block .cloud-3-2 span {
  margin-top: 1.04167vw;
  margin-right: 0.80128vw;
}

.section_four .main-block .cloud-4-1 {
  width: 15.10417vw;
  height: 9.9359vw;
  left: 49.11859vw;
  top: 21.55449vw;
  background-image: url("../images/t41.png");
  z-index: 1;
}

.section_four .main-block .cloud-4-2 {
  width: 11.01763vw;
  height: 15.98558vw;
  left: 59.97596vw;
  top: 14.0625vw;
  background-image: url("../images/t42.png");
  color: #f04132;
}

.section_four .main-block .cloud-4-2 span {
  margin-bottom: 0.20032vw;
  margin-left: 0.5609vw;
}

.section_four .text-bottom {
  display: flex;
  margin-top: -1.0016vw;
  margin-left: 15.625vw;
}

.section_four .text-bottom p {
  color: #24186a;
  font-family: "Montserrat";
  font-size: 0.96154vw;
  font-weight: 500;
  line-height: 1.08173vw;
}

.section_four .text-bottom p + p {
  margin-top: 1em;
}

.section_four .text-bottom .left {
  width: 31.25vw;
}

.section_four .text-bottom .right {
  width: 26.04167vw;
  margin-left: 3.125vw;
}

.section_four .spec-block {
  margin-top: 2.20353vw;
}

.section_four .spec-block .text {
  color: #24186a;
  font-size: 1.44231vw;
  font-weight: 400;
  padding: 0.32051vw 0.64103vw 0.32051vw 0.64103vw;
  box-sizing: border-box;
  margin-left: 13.54167vw;
  line-height: 1.4;
  width: 72.91667vw;
}

.section_four .spec-block .text span {
  padding: 0.32051vw 0.64103vw 0.32051vw 0vw;
  background: #fdb315;
  box-shadow: -0.64103vw 0 0 #fdb315;
  white-space: pre-wrap;
}

.section_four .spec-block .icons {
  margin-top: 1.76282vw;
  margin-left: 13.54167vw;
  position: relative;
  height: 21.43429vw;
}

.section_four .spec-block .icons .cloud {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section_four .spec-block .icons .cloud-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.96154vw;
  font-weight: 400;
}

.section_four .spec-block .icons .cloud-5-1 {
  width: 15.0641vw;
  height: 12.17949vw;
  left: 0vw;
  top: 0.28045vw;
  background-image: url("../images/t51.png");
  z-index: 1;
}

.section_four .spec-block .icons .cloud-5-2 {
  width: 17.06731vw;
  height: 11.21795vw;
  left: 3.92628vw;
  top: 10.13622vw;
  background-image: url("../images/t52.png");
  color: #24186a;
  font-size: 0.76122vw;
}

.section_four .spec-block .icons .cloud-5-2 span {
  margin-top: 0.60096vw;
  margin-left: 3.20513vw;
}

.section_four .spec-block .icons .cloud-6-1 {
  width: 10.53686vw;
  height: 11.13782vw;
  left: 21.83494vw;
  top: 10.13622vw;
  background-image: url("../images/t61.png");
  z-index: 1;
}

.section_four .spec-block .icons .cloud-6-2 {
  width: 12.66026vw;
  height: 8.57372vw;
  left: 16.94712vw;
  top: 0.20032vw;
  background-image: url("../images/t62.png");
  color: #f04132;
}

.section_four .spec-block .icons .cloud-6-2 span {
  margin-right: 0.88141vw;
  margin-bottom: 0.32051vw;
}

.section_four .spec-block .icons .cloud-7-1 {
  width: 10.77724vw;
  height: 9.25481vw;
  left: 32.69231vw;
  top: 0.5609vw;
  background-image: url("../images/t71.png");
  z-index: 1;
}

.section_four .spec-block .icons .cloud-7-2 {
  width: 11.17788vw;
  height: 9.05449vw;
  left: 37.86058vw;
  top: 9.01442vw;
  background-image: url("../images/t72.png");
  color: #24186a;
}

.section_four .spec-block .icons .cloud-7-2 span {
  margin-left: 0.96154vw;
  margin-top: 0.68109vw;
}

.section_four .spec-block .icons .cloud-8-1 {
  width: 12.33974vw;
  height: 10.77724vw;
  left: 58.61378vw;
  top: 9.09455vw;
  background-image: url("../images/t81.png");
  z-index: 1;
}

.section_four .spec-block .icons .cloud-8-2 {
  width: 14.90385vw;
  height: 12.82051vw;
  left: 48.75801vw;
  top: 0vw;
  background-image: url("../images/t82.png");
  color: #f04132;
}

.section_four .spec-block .icons .cloud-8-2 span {
  margin-bottom: 1.28205vw;
}

.section_four .spec-block .flex-block {
  margin-top: 0.96154vw;
  display: flex;
  margin-left: 15.86538vw;
}

.section_four .spec-block .flex-block p {
  color: #24186a;
  font-family: "Montserrat";
  font-size: 0.96154vw;
  font-weight: 500;
  line-height: 1.08173vw;
}

.section_four .spec-block .flex-block p + p {
  margin-top: 1em;
}

.section_four .spec-block .flex-block .left {
  width: 23.03686vw;
  margin-top: 2.32372vw;
}

.section_four .spec-block .flex-block .right {
  width: 20.83333vw;
  margin-top: 2.80449vw;
}

.section_four .spec-block .flex-block .center {
  width: 12.82051vw;
  margin-left: 1.36218vw;
  margin-right: 2.68429vw;
}

.section_four .spec-block .flex-block .center img {
  width: 100%;
  display: block;
}

.section_four .spec-block .last-text {
  color: #fdb315;
  font-size: 1.44231vw;
  font-weight: 400;
  padding: 0.32051vw 0.64103vw 0.32051vw 0.64103vw;
  box-sizing: border-box;
  margin-left: 25.28045vw;
  margin-top: 0.80128vw;
}

.section_four .spec-block .last-text span {
  padding: 0.32051vw 0.64103vw 0.32051vw 0vw;
  background: #24186a;
  box-shadow: -0.64103vw 0 0 #24186a;
  white-space: pre-wrap;
}

.section_four .spec-block .last-text a {
  color: #fdb315;
  text-decoration: none;
}

.section_four .spec-block .last-text a:hover {
  color: #f04132;
}

.section_five {
  padding: 0.78125vw 0.88542vw;
}

.section_five img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 94.79167vw;
  }

  .social-block {
    display: flex;
  }

  .social-block {
    justify-content: center;
  }

  .social-block .soc-link {
    width: 6.51042vw;
  }

  .social-block .soc-link {
    height: 6.51042vw;
  }

  .social-block .soc-link {
    background: #24186a;
  }

  .social-block .soc-link svg {
    width: 2.60417vw;
  }

  .social-block .soc-link svg {
    height: 2.60417vw;
  }

  .social-block .soc-link + .soc-link {
    margin-top: 0;
  }

  .social-block .soc-link + .soc-link {
    margin-left: 1.30208vw;
  }

  .text_style {
    font-size: 3.125vw;
  }

  .text_style {
    line-height: 3.51563vw;
  }

  .text_style p + p {
    margin-top: 3.25521vw;
  }

  .section_one {
    background: url("../images/bg-top_mobile.jpg") left top no-repeat;
  }

  .section_one {
    background-size: 100% auto;
  }

  .section_one::before {
    height: calc(100% - 40.10417vw);
  }

  .section_one::before {
    width: 100%;
  }

  .section_one::before {
    top: auto;
  }

  .section_one::before {
    bottom: 0;
  }

  .section_one::before {
    background: url("../images/bgm1.png") center top no-repeat;
  }

  .section_one::before {
    background-size: cover;
  }

  .section_one .logo {
    top: 4.81771vw;
  }

  .section_one .logo {
    left: 3.25521vw;
  }

  .section_one .logo {
    width: 43.88021vw;
  }

  .section_one .social-block {
    position: static;
  }

  .section_one .social-block {
    margin-top: 5vh;
  }

  .section_one .maxim {
    top: 2.99479vw;
  }

  .section_one .maxim {
    right: 0;
  }

  .section_one .maxim {
    width: 30.20833vw;
  }

  .section_one .maxim img.desktop {
    display: none;
  }

  .section_one .maxim img.mobile {
    display: block;
  }

  .section_one .daa {
    width: 25.39063vw;
  }

  .section_one .daa {
    height: 17.83854vw;
  }

  .section_one .daa {
    left: 67.57813vw;
  }

  .section_one .daa {
    top: 14.71354vw;
  }

  .section_one .daa span {
    font-size: 3.125vw;
  }

  .section_one .right {
    padding-top: 30vh;
  }

  .section_one .right {
    margin: 0 0.65104vw;
  }

  .section_one .right .title {
    font-size: 10.41667vw;
  }

  .section_one .right .title span {
    padding: 2.21354vw 2.21354vw 1.95313vw;
  }

  .section_one .right .text {
    width: 95%;
  }

  .section_one .right .text {
    margin-top: 5vh;
  }

  .section_one .arrow-down {
    width: 4.16667vw;
  }

  .section_one .arrow-down {
    margin-left: auto;
  }

  .section_one .arrow-down {
    margin-right: auto;
  }

  .section_one .arrow-down {
    margin-top: 2vh;
  }

  .section_one .arrow-down {
    height: 3.25521vw;
  }

  .section_one .arrow-down {
    position: static;
  }

  .section_two::before {
    width: 67.05729vw;
  }

  .section_two::before {
    background: url("../images/bgm2.png") right top no-repeat;
  }

  .section_two::before {
    background-size: cover;
  }

  .section_two .middle {
    width: 30.59896vw;
  }

  .section_two .middle {
    left: -2.60417vw;
  }

  .section_two .middle {
    margin-left: 0;
  }

  .section_two .middle {
    margin-top: -18.88021vw;
  }

  .section_two .middle .title {
    font-size: 9.375vw;
  }

  .section_two .middle .text {
    font-size: 3.38542vw;
  }

  .section_two .middle .text {
    margin-top: 1.69271vw;
  }

  .section_two .middle .text {
    text-align: left;
  }

  .section_two .middle .text {
    margin-left: 2.60417vw;
  }

  .section_two .slider .girls {
    width: 100%;
  }

  .section_two .slider .girls {
    flex-direction: column;
  }

  .section_two .slider .girls .girl-block {
    width: 100%;
  }

  .section_two .slider .girls .girl-block {
    height: 57.94271vw;
  }

  .section_two .slider .girls .girl-block .img {
    width: 42.44792vw;
  }

  .section_two .slider .girls .girl-block .img {
    height: 57.94271vw;
  }

  .section_two .slider .girls .girl-block .img {
    margin: 0 auto;
  }

  .section_two .slider .girls .girl-block .img .likes {
    font-size: 7.68229vw;
  }

  .section_two .slider .girls .girl-block .img .like {
    width: 6.51042vw;
  }

  .section_two .slider .girls .girl-block .img .like {
    height: 6.51042vw;
  }

  .section_two .slider .girls .girl-block .img .like {
    margin-left: 0;
  }

  .section_two .slider .girls .girl-block .img .like {
    left: auto;
  }

  .section_two .slider .girls .girl-block .img .like {
    bottom: 2.34375vw;
  }

  .section_two .slider .girls .girl-block .img .like {
    right: 2.34375vw;
  }

  .section_two .slider .girls .girl-block .img.liked {
    border-width: 1.04167vw;
  }

  .section_two .slider .girls .girl-block .flex-row {
    margin-top: 0;
  }

  .section_two .slider .girls .girl-block .flex-row {
    position: absolute;
  }

  .section_two .slider .girls .girl-block .flex-row {
    left: 0;
  }

  .section_two .slider .girls .girl-block .flex-row {
    width: 84.375vw;
  }

  .section_two .slider .girls .girl-block .flex-row .play {
    width: 12.10938vw;
  }

  .section_two .slider .girls .girl-block .flex-row .play {
    height: 12.10938vw;
  }

  .section_two .slider .girls .girl-block .flex-row .name {
    font-size: 4.6875vw;
  }

  .section_two .slider .girls .girl-block .flex-row .name {
    padding: 0.65104vw 1.30208vw;
  }

  .section_two .slider .girls .girl-block .flex-row .name span {
    font-size: 4.6875vw;
  }

  .section_two .slider .girls .girl-block .flex-row .name span {
    padding: 0.65104vw 1.30208vw;
  }

  .section_two .slider .girls .girl-block .flex-row .name span {
    padding-left: 0;
  }

  .section_two .slider .girls .girl-block .flex-row .name span {
    box-shadow: -1.30208vw 0 0 #24186a;
  }

  .section_two .slider .girls .girl-block .flex-row .age {
    display: none;
  }

  .section_two .slider .girls .girl-block.left-girl .flex-row {
    top: 0;
  }

  .section_two .slider .girls .girl-block.right-girl {
    margin-top: 5.46875vw;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row {
    bottom: 0;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .play {
    background-image: url("../images/play.png");
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .play {
    position: relative;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .play {
    top: -44.92188vw;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .play.pause {
    background-image: url("../images/pause.png");
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .name {
    color: #f04132;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .name span {
    background: #24186a;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .name span {
    box-shadow: -1.30208vw 0 0 #24186a;
  }

  .section_two .slider .girls .girl-block.right-girl .flex-row .age {
    color: #24186a;
  }

  .section_two .slider .swiper-button-prev,
  .section_two .slider .swiper-button-next {
    display: none;
  }

  .section_two .slider .swiper-pagination {
    display: block;
  }

  .section_three::before {
    background: url("../images/bgm3.png") right center no-repeat;
  }

  .section_three::before {
    background-size: cover;
  }

  .section_three::before {
    width: 67.83854vw;
  }

  .section_three .title {
    font-size: 13.67188vw;
  }

  .section_three .title {
    width: 100%;
  }

  .section_three .title {
    box-sizing: border-box;
  }

  .section_three .title span {
    padding: 2.60417vw 2.60417vw 2.34375vw;
  }

  .section_three .title span {
    width: 100%;
  }

  .section_three .title span {
    box-sizing: border-box;
  }

  .section_three .list {
    padding: 0;
  }

  .section_three .list ol {
    width: 100%;
  }

  .section_three .list ol > li::before {
    font-size: 9.375vw;
  }

  .section_three .list ol > li::before {
    padding: 1vh 1.95313vw 1vh 0;
  }

  .section_three .list ol > li > div.name {
    font-size: 5.46875vw;
  }

  .section_three .list ol > li > div.name {
    padding: 2.60417vw 3.90625vw 2.60417vw 1.95313vw;
  }

  .section_three .list ol > li > div.name {
    width: auto;
  }

  .section_three .list ol > li > div.name span {
    box-shadow: -1.95313vw 0 0 #24186a;
  }

  .section_three .list ol > li > div.name span {
    padding: 1.04167vw 1.95313vw 1.04167vw 0vw;
  }

  .section_three .list ol > li > div.score {
    font-size: 7.8125vw;
  }

  .section_four {
    padding-top: 2.99479vw;
  }

  .section_four {
    padding-bottom: 6.38021vw;
  }

  .section_four::before {
    background: url("../images/bgm4.png") right center no-repeat;
  }

  .section_four::before {
    background-size: cover;
  }

  .section_four::before {
    width: 66.79688vw;
  }

  .section_four .title {
    font-size: 8.72396vw;
  }

  .section_four .title {
    margin-left: 0vw;
  }

  .section_four .title span {
    padding: 1.69271vw 2.34375vw 1.17188vw;
  }

  .section_four .main-block {
    width: 100vw;
  }

  .section_four .main-block {
    margin-left: -2.60417vw;
  }

  .section_four .main-block {
    margin-right: -2.60417vw;
  }

  .section_four .main-block {
    margin-top: 4.16667vw;
  }

  .section_four .main-block {
    padding-top: 0vw;
  }

  .section_four .main-block .main-img {
    width: 106.25vw;
  }

  .section_four .main-block .main-img {
    margin-left: -3.125vw;
  }

  .section_four .main-block .main-img {
    margin-right: -3.125vw;
  }

  .section_four .main-block .main-img {
    margin-top: 35.15625vw;
  }

  .section_four .main-block .top-text {
    font-size: 4.6875vw;
  }

  .section_four .main-block .top-text {
    padding: 1.04167vw 1.95313vw 1.04167vw 1.95313vw;
  }

  .section_four .main-block .top-text {
    position: static;
  }

  .section_four .main-block .top-text {
    margin-left: 2.60417vw;
  }

  .section_four .main-block .top-text {
    color: #fdb315;
  }

  .section_four .main-block .top-text span {
    box-shadow: -1.95313vw 0 0 #24186a;
  }

  .section_four .main-block .top-text span {
    padding: 1.04167vw 1.95313vw 1.04167vw 0vw;
  }

  .section_four .main-block .top-text-2 {
    font-size: 3.90625vw;
  }

  .section_four .main-block .top-text-2 {
    color: #24186a;
  }

  .section_four .main-block .top-text-2 {
    margin-top: 4.29688vw;
  }

  .section_four .main-block .top-text-2 span {
    box-shadow: none;
  }

  .section_four .main-block .top-text-2 span {
    background: none;
  }

  .section_four .main-block .note-1 {
    width: 6.64063vw;
  }

  .section_four .main-block .note-1 {
    left: 85.9375vw;
  }

  .section_four .main-block .note-1 {
    top: 32.55208vw;
  }

  .section_four .main-block .note-2 {
    display: none;
  }

  .section_four .main-block .note-3 {
    width: 6.25vw;
  }

  .section_four .main-block .note-3 {
    left: 66.01563vw;
  }

  .section_four .main-block .note-3 {
    top: 66.92708vw;
  }

  .section_four .main-block .note-4 {
    display: none;
  }

  .section_four .main-block .note-5 {
    display: none;
  }

  .section_four .main-block .note-6 {
    width: 4.42708vw;
  }

  .section_four .main-block .note-6 {
    left: 2.21354vw;
  }

  .section_four .main-block .note-6 {
    top: 91.27604vw;
  }

  .section_four .main-block .cloud-text {
    font-size: 2.08333vw;
  }

  .section_four .main-block .cloud-1-1 {
    width: 24.08854vw;
  }

  .section_four .main-block .cloud-1-1 {
    height: 20.96354vw;
  }

  .section_four .main-block .cloud-1-1 {
    left: 7.42188vw;
  }

  .section_four .main-block .cloud-1-1 {
    top: 68.75vw;
  }

  .section_four .main-block .cloud-1-2 {
    width: 24.21875vw;
  }

  .section_four .main-block .cloud-1-2 {
    height: 31.90104vw;
  }

  .section_four .main-block .cloud-1-2 {
    left: -1.04167vw;
  }

  .section_four .main-block .cloud-1-2 {
    top: 46.875vw;
  }

  .section_four .main-block .cloud-1-2 span {
    margin-bottom: 4.16667vw;
  }

  .section_four .main-block .cloud-1-2 span {
    margin-right: 1.04167vw;
  }

  .section_four .main-block .cloud-2-1 {
    width: 29.03646vw;
  }

  .section_four .main-block .cloud-2-1 {
    height: 30.98958vw;
  }

  .section_four .main-block .cloud-2-1 {
    left: 25.26042vw;
  }

  .section_four .main-block .cloud-2-1 {
    top: 45.57292vw;
  }

  .section_four .main-block .cloud-2-2 {
    width: 29.42708vw;
  }

  .section_four .main-block .cloud-2-2 {
    height: 19.79167vw;
  }

  .section_four .main-block .cloud-2-2 {
    left: 33.72396vw;
  }

  .section_four .main-block .cloud-2-2 {
    top: 71.74479vw;
  }

  .section_four .main-block .cloud-2-2 {
    font-size: 1.95313vw;
  }

  .section_four .main-block .cloud-2-2 span {
    margin-left: 5.07813vw;
  }

  .section_four .main-block .cloud-2-2 span {
    margin-top: 2.21354vw;
  }

  .section_four .main-block .cloud-3-1 {
    width: 29.42708vw;
  }

  .section_four .main-block .cloud-3-1 {
    height: 25.39063vw;
  }

  .section_four .main-block .cloud-3-1 {
    left: 54.81771vw;
  }

  .section_four .main-block .cloud-3-1 {
    top: 38.67188vw;
  }

  .section_four .main-block .cloud-3-2 {
    width: 24.34896vw;
  }

  .section_four .main-block .cloud-3-2 {
    height: 21.35417vw;
  }

  .section_four .main-block .cloud-3-2 {
    left: 78.64583vw;
  }

  .section_four .main-block .cloud-3-2 {
    top: 52.08333vw;
  }

  .section_four .main-block .cloud-3-2 {
    font-size: 1.95313vw;
  }

  .section_four .main-block .cloud-3-2 span {
    margin-top: 1.82292vw;
  }

  .section_four .main-block .cloud-3-2 span {
    margin-right: 1.17188vw;
  }

  .section_four .main-block .cloud-4-1 {
    width: 34.24479vw;
  }

  .section_four .main-block .cloud-4-1 {
    height: 22.52604vw;
  }

  .section_four .main-block .cloud-4-1 {
    left: 51.17188vw;
  }

  .section_four .main-block .cloud-4-1 {
    top: 84.63542vw;
  }

  .section_four .main-block .cloud-4-2 {
    width: 25vw;
  }

  .section_four .main-block .cloud-4-2 {
    height: 36.19792vw;
  }

  .section_four .main-block .cloud-4-2 {
    left: 75.78125vw;
  }

  .section_four .main-block .cloud-4-2 {
    top: 67.44792vw;
  }

  .section_four .main-block .cloud-4-2 {
    font-size: 2.21354vw;
  }

  .section_four .main-block .cloud-4-2 span {
    margin-bottom: 0.52083vw;
  }

  .section_four .main-block .cloud-4-2 span {
    margin-left: 1.43229vw;
  }

  .section_four .text-bottom {
    margin-top: 1.5625vw;
  }

  .section_four .text-bottom {
    margin-left: 1.30208vw;
  }

  .section_four .text-bottom {
    margin-right: 1.30208vw;
  }

  .section_four .text-bottom {
    display: block;
  }

  .section_four .text-bottom p {
    font-size: 3.125vw;
  }

  .section_four .text-bottom p {
    line-height: 3.51563vw;
  }

  .section_four .text-bottom .left {
    width: 100%;
  }

  .section_four .text-bottom .right {
    width: 100%;
  }

  .section_four .text-bottom .right {
    margin-left: 0;
  }

  .section_four .text-bottom .right {
    margin-top: 3.125vw;
  }

  .section_four .spec-block {
    margin-top: 3.90625vw;
  }

  .section_four .spec-block .text {
    width: 100%;
  }

  .section_four .spec-block .text {
    font-size: 3.90625vw;
  }

  .section_four .spec-block .text {
    padding: 1.04167vw 2.08333vw 1.04167vw 2.08333vw;
  }

  .section_four .spec-block .text {
    margin-left: 0;
  }

  .section_four .spec-block .text span {
    padding: 1.04167vw 2.08333vw 1.04167vw 0vw;
  }

  .section_four .spec-block .text span {
    box-shadow: -2.08333vw 0 0 #fdb315;
  }

  .section_four .spec-block .icons {
    height: 128.25521vw;
  }

  .section_four .spec-block .icons {
    margin-left: 0;
  }

  .section_four .spec-block .icons {
    margin-top: 0.91146vw;
  }

  .section_four .spec-block .icons .cloud-text {
    font-size: 3.125vw;
  }

  .section_four .spec-block .icons .cloud-5-1 {
    width: 48.95833vw;
  }

  .section_four .spec-block .icons .cloud-5-1 {
    height: 39.58333vw;
  }

  .section_four .spec-block .icons .cloud-5-1 {
    left: -0.39063vw;
  }

  .section_four .spec-block .icons .cloud-5-1 {
    top: 3.25521vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    width: 55.46875vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    height: 36.45833vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    left: 1.82292vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    top: 37.36979vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    font-size: 2.47396vw;
  }

  .section_four .spec-block .icons .cloud-5-2 {
    background-image: url("../images/t52m.png");
  }

  .section_four .spec-block .icons .cloud-5-2 span {
    margin-top: 1.95313vw;
  }

  .section_four .spec-block .icons .cloud-5-2 span {
    margin-left: 10.41667vw;
  }

  .section_four .spec-block .icons .cloud-6-1 {
    width: 34.24479vw;
  }

  .section_four .spec-block .icons .cloud-6-1 {
    height: 36.19792vw;
  }

  .section_four .spec-block .icons .cloud-6-1 {
    left: 61.45833vw;
  }

  .section_four .spec-block .icons .cloud-6-1 {
    top: 22.26563vw;
  }

  .section_four .spec-block .icons .cloud-6-2 {
    width: 41.14583vw;
  }

  .section_four .spec-block .icons .cloud-6-2 {
    height: 27.86458vw;
  }

  .section_four .spec-block .icons .cloud-6-2 {
    left: 46.74479vw;
  }

  .section_four .spec-block .icons .cloud-6-2 {
    top: 0vw;
  }

  .section_four .spec-block .icons .cloud-6-2 {
    background-image: url("../images/t62m.png");
  }

  .section_four .spec-block .icons .cloud-6-2 span {
    margin-right: 2.86458vw;
  }

  .section_four .spec-block .icons .cloud-6-2 span {
    margin-bottom: 1.04167vw;
  }

  .section_four .spec-block .icons .cloud-7-1 {
    width: 35.02604vw;
  }

  .section_four .spec-block .icons .cloud-7-1 {
    height: 30.07813vw;
  }

  .section_four .spec-block .icons .cloud-7-1 {
    left: 1.30208vw;
  }

  .section_four .spec-block .icons .cloud-7-1 {
    top: 67.05729vw;
  }

  .section_four .spec-block .icons .cloud-7-2 {
    width: 36.32813vw;
  }

  .section_four .spec-block .icons .cloud-7-2 {
    height: 29.42708vw;
  }

  .section_four .spec-block .icons .cloud-7-2 {
    left: 11.45833vw;
  }

  .section_four .spec-block .icons .cloud-7-2 {
    top: 98.95833vw;
  }

  .section_four .spec-block .icons .cloud-7-2 {
    background-image: url("../images/t72m.png");
  }

  .section_four .spec-block .icons .cloud-7-2 span {
    margin-left: 3.125vw;
  }

  .section_four .spec-block .icons .cloud-7-2 span {
    margin-top: 2.21354vw;
  }

  .section_four .spec-block .icons .cloud-8-1 {
    width: 40.10417vw;
  }

  .section_four .spec-block .icons .cloud-8-1 {
    height: 35.02604vw;
  }

  .section_four .spec-block .icons .cloud-8-1 {
    left: 55.85938vw;
  }

  .section_four .spec-block .icons .cloud-8-1 {
    top: 90.49479vw;
  }

  .section_four .spec-block .icons .cloud-8-2 {
    width: 48.4375vw;
  }

  .section_four .spec-block .icons .cloud-8-2 {
    height: 41.66667vw;
  }

  .section_four .spec-block .icons .cloud-8-2 {
    left: 42.05729vw;
  }

  .section_four .spec-block .icons .cloud-8-2 {
    top: 61.19792vw;
  }

  .section_four .spec-block .icons .cloud-8-2 span {
    margin-bottom: 4.16667vw;
  }

  .section_four .spec-block .flex-block {
    margin-top: 5.46875vw;
  }

  .section_four .spec-block .flex-block {
    margin-left: 0;
  }

  .section_four .spec-block .flex-block {
    display: block;
  }

  .section_four .spec-block .flex-block p {
    font-size: 3.125vw;
  }

  .section_four .spec-block .flex-block p {
    line-height: 3.51563vw;
  }

  .section_four .spec-block .flex-block .left {
    width: 95%;
  }

  .section_four .spec-block .flex-block .left {
    margin-left: 1.95313vw;
  }

  .section_four .spec-block .flex-block .left {
    margin-righ: 1.95313vw;
  }

  .section_four .spec-block .flex-block .right {
    width: 95%;
  }

  .section_four .spec-block .flex-block .right {
    margin-top: 3.25521vw;
  }

  .section_four .spec-block .flex-block .right {
    margin-left: 1.95313vw;
  }

  .section_four .spec-block .flex-block .right {
    margin-righ: 1.95313vw;
  }

  .section_four .spec-block .flex-block .center {
    width: 41.66667vw;
  }

  .section_four .spec-block .flex-block .center {
    margin: 3.25521vw auto 0;
  }

  .section_four .spec-block .last-text {
    font-size: 4.6875vw;
  }

  .section_four .spec-block .last-text {
    padding: 1.04167vw 2.08333vw 1.04167vw 2.08333vw;
  }

  .section_four .spec-block .last-text {
    margin-left: 1.30208vw;
  }

  .section_four .spec-block .last-text {
    margin-right: 1.30208vw;
  }

  .section_four .spec-block .last-text {
    margin-top: 7.8125vw;
  }

  .section_four .spec-block .last-text span {
    padding: 1.04167vw 2.08333vw 1.04167vw 0vw;
  }

  .section_four .spec-block .last-text span {
    box-shadow: -2.08333vw 0 0 #24186a;
  }

  .section_five {
    padding: 3.25521vw 2.60417vw;
  }

  .section_five img {
    transform: scaleY(1.6);
  }
}